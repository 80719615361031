<template>
  <div
    class="card container"
    :style="{
      zIndex: '1000',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '21rem',
    }"
  >
    <div
      class="card main-gradient"
      style="
        width: 10rem;
        padding: 0.5rem;
        text-align: center;
        position: absolute;
        top: -2rem;
        left: 2rem;
        filter: drop-shadow(0.3rem 0.3rem 0.4rem var(--clr-main));
      "
    >
      <span style="color: white; font-size: 2rem"
        ><i class="fa-solid fa-chess"></i> Play</span
      >
    </div>
    <div
      v-if="loading"
      style="display: flex; justify-content: center; margin-top: 2rem"
    >
      <LoaderNew />
    </div>
    <div v-else class="inner-container" style="min-height: 16rem">
      <div
        :style="{
          flexBasis: !isMobileWidth() && suggestedBots != null ? '0' : '10rem',
          flexGrow: '0',
        }"
      ></div>
      <div
        :style="{
          width: '100%',
          flexGrow: '1',
          marginRight: !isMobileWidth() && showTime ? '1rem' : '',
        }"
      >
        <IntroGame v-if="suggestedBots == null" />
        <SuggestedBots v-else />
      </div>
      <div
        :style="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobileWidth() ? 'center' : 'end',
          flexBasis: '10rem',
          flexGrow: '0',
        }"
      >
        <div
          :style="{
            display: 'flex',
            gap: '0.5rem',
          }"
          role="button"
          @click="showTime = !showTime"
        >
          <div
            class="card"
            :style="{
              justifyContent: 'center',
              alignItems: 'center',
              width: isMobileWidth() ? '2.3rem' : '4rem',
              height: isMobileWidth() ? '2.3rem' : '4rem',
            }"
          >
            <i
              class="fa-solid fa-chess-clock"
              :style="{
                color: 'var(--clr-main)',
                fontSize: isMobileWidth() ? '1.2rem' : '1.5rem',
              }"
            />
            <i
              :class="
                'fa-solid ' + (showTime ? 'fa-caret-up' : 'fa-caret-down')
              "
              :style="{ fontSize: isMobileWidth() ? '0.7rem' : '1rem' }"
            />
          </div>
          <div
            :style="{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }"
          >
            <span
              :style="{
                color: 'white',
                fontSize: isMobileWidth() ? '0.7rem' : '1.2rem',
                whiteSpace: 'nowrap',
              }"
              >Time control</span
            >
            <span
              style="
                color: var(--clr-accent);
                font-size: 1rem;
                white-space: nowrap;
              "
              v-if="currentTimeControl"
              >{{ timeControlCategory }}
              <span style="color: white; font-size: 0.8rem"
                >({{ timeControlText }})</span
              ></span
            >
            <span
              v-else
              style="
                color: var(--clr-accent);
                font-size: 1rem;
                white-space: nowrap;
              "
            >
              Unlimited
            </span>
          </div>
        </div>
        <TimeSelection v-if="showTime" @update="updateTimecontrol" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { NextOpponentSuggestion, TimeControl } from "@/types/apitypes";
import { useModalsStore } from "@/stores/modalsStore";
import { usePageStore } from "@/stores/pageStore";
import TimeSelection from "@/components/dashboard/playbox/TimeSelection.vue";
import { onMounted, ref, watch } from "vue";
import LoaderNew from "@/components/util/LoaderNew.vue";
import { useBackgroundStore } from "@/stores/backgroundStore";
import IntroGame from "@/components/dashboard/playbox/IntroGame.vue";
import SuggestedBots from "@/components/dashboard/playbox/SuggestedBots.vue";
import { isMobileWidth } from "@/util/util";
import { getTimeCategory } from "@/util/time";
import { useBotsStore } from "@/stores/botStore";

const ms = useModalsStore();
const ps = usePageStore();
const bs = useBackgroundStore();
const emit = defineEmits(["userInput", "setBoardBg"]);

let currentTimeControl = ref(
  usePageStore().gameSettings?.timeControl
    ? usePageStore().gameSettings?.timeControl
    : null
);
let timeControlText = ref("");
let timeControlCategory = ref("");

let showTime = ref(false);
let suggestedBots = ref<NextOpponentSuggestion | null>(null);
let loading = ref(true);

onMounted(() => {
  loading.value = true;
  useBotsStore()
    .getNextOpponent(null)
    .then((n) => {
      suggestedBots.value = n;
      loading.value = false;
    });
});

watch(
  () => currentTimeControl.value,
  (newTimeControl) => {
    updateTimeControlTexts(newTimeControl as TimeControl | null | undefined);
  },
  { immediate: true }
);

function updateTimeControlTexts(timeControl: TimeControl | null | undefined) {
  if (timeControl == null) {
    timeControlText.value = "";
    timeControlCategory.value = "Unlimited";
  } else {
    timeControlText.value =
      timeControl.initial / 60 / 1000 + " + " + timeControl.increment / 1000;
    timeControlCategory.value = getTimeCategory(timeControl);
  }
}

function updateTimecontrol(timeControl: TimeControl | null) {
  showTime.value = false;
  currentTimeControl.value = timeControl;
}
</script>

<style scoped>
.inner-container {
  width: 100%;
  display: flex;
  align-items: start;
}

h2 {
  text-align: center;
  margin-block: 1rem;
}

.card-text p {
  color: green;
}

@media (max-width: 572px) {
  .inner-container {
    flex-direction: column;
    align-items: center;
  }

  .inner-container > :nth-child(1),
  .inner-container > :nth-child(3) {
    flex-basis: 2rem !important;
  }
}
</style>
