<template>
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div
          class="modal-header"
          style="border-bottom: 0px; justify-content: right; z-index: 10"
        >
          <a
            type="button"
            id="loginModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 1rem; height: 1rem"
            />
          </a>
        </div>
        <div class="modal-body">
          <h3
            v-if="loginModalState == LoginModalState.Login"
            style="margin-top: -3rem; margin-bottom: 2rem"
          >
            Log In
          </h3>
          <h3
            v-if="loginModalState == LoginModalState.Signup"
            style="margin-top: -3rem; margin-bottom: 2rem"
          >
            Sign Up
          </h3>
          <h3
            v-if="loginModalState == LoginModalState.Reset"
            style="margin-top: -3rem; margin-bottom: 2rem"
          >
            Reset password
          </h3>
          <div v-if="loginModalState == LoginModalState.ResetSent">
            <div class="my-2">
              <span>Reset password email sent, check your inbox</span>
            </div>
          </div>
          <div v-if="loginModalState == LoginModalState.Reset">
            <div class="my-2">
              <input
                type="text"
                placeholder="E-mail"
                class="form-control"
                id="emailInput"
                ref="emailInputReset"
                v-model="email"
                required
                @keydown.enter="resetPassword()"
              />
              <span style="color: red">{{ emailErrorText }}</span>
            </div>
          </div>
          <div v-if="loginModalState == LoginModalState.Login">
            <div class="my-2">
              <input
                type="text"
                placeholder="E-mail"
                class="form-control"
                id="emailInput"
                ref="emailInputLogin"
                v-model="email"
                required
                @keydown.enter="login()"
              />
              <span style="color: red">{{ emailErrorText }}</span>
            </div>
            <div style="position: relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                placeholder="Password"
                class="form-control"
                id="passwordInput"
                v-model="password"
                required
                @keydown.enter="login()"
              />
              <i
                :class="
                  'fa-solid position-in-input ' +
                  (showPassword ? 'fa-eye-slash' : 'fa-eye')
                "
                @click="
                  () => {
                    showPassword = !showPassword;
                    track('login_modal', 'show_login_password', 'click');
                  }
                "
              ></i>
            </div>
            <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{
              passwordErrorText
            }}</span>
            <br />
            <div style="text-align: center">
              <span
                style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
                class="ph-no-capture"
                @click="
                  () => {
                    setType(LoginModalState.Reset);
                    track('login_modal', 'reset_password_toggle', 'click');
                  }
                "
              >
                Forgot password?</span
              >
            </div>
          </div>
          <div v-if="loginModalState == LoginModalState.Signup">
            <div class="my-2">
              <input
                type="text"
                placeholder="E-mail"
                class="form-control"
                id="emailInput"
                ref="emailInputSignup"
                v-model="email"
                required
                @keydown.enter="signup()"
              />
              <span style="color: red">{{ emailErrorText }}</span>
            </div>
            <div style="position: relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                placeholder="Password"
                class="form-control"
                id="passwordInput"
                v-model="password"
                required
                @keydown.enter="signup()"
              />
              <i
                :class="
                  'fa-solid position-in-input ' +
                  (password ? 'extra-margin ' : '') +
                  (showPassword ? 'fa-eye-slash' : 'fa-eye')
                "
                @click="
                  () => {
                    showPassword = !showPassword;
                    track('login_modal', 'show_signup_password', 'click');
                  }
                "
              ></i>
            </div>
            <div class="mt-2" style="position: relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                placeholder="Re-type password"
                class="form-control"
                id="passwordInputRetype"
                v-model="passwordRetype"
                required
                @keydown.enter="signup()"
              />
              <i
                :class="
                  'fa-solid position-in-input ' +
                  (passwordRetype ? 'extra-margin ' : '') +
                  (showPassword ? 'fa-eye-slash' : 'fa-eye')
                "
                @click="
                  () => {
                    showPassword = !showPassword;
                    track('login_modal', 'show_password', 'click');
                  }
                "
              ></i>
            </div>
            <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{
              passwordErrorText
            }}</span>
            <div
              class="d-flex mt-3 ph-no-capture"
              @click="
                () => {
                  newsletterConsent = !newsletterConsent;
                  track('login_modal', 'toggle_newsletter_consent', 'click', {
                    consent: newsletterConsent,
                  });
                }
              "
              role="button"
            >
              <div style="width: 2rem; margin: -0.5rem 0 0 0.5rem">
                <input
                  type="checkbox"
                  id="newsletter-consent"
                  name="newsletter-consent"
                  value="newsletter-consent"
                  v-model="newsletterConsent"
                />
              </div>
              <span style="font-size: 0.8rem"
                >Yes, sign me up to get occasional news and tips and
                tricks!</span
              >
            </div>
          </div>
        </div>
        <div
          class="modal-footer"
          v-if="loginModalState != LoginModalState.ResetSent"
        >
          <div v-if="loginModalState == LoginModalState.Reset">
            <button
              type="button"
              class="btn btn-info ph-no-capture"
              :disabled="loading"
              @click="
                () => {
                  resetPassword();
                  track('login_modal', 'reset_password_button', 'click');
                }
              "
              @keydown.enter="
                () => {
                  resetPassword();
                  track(
                    'login_modal',
                    'reset_password_button',
                    'keydown_enter'
                  );
                }
              "
              style="height: 2.5rem; width: 8rem"
            >
              {{ loading ? "" : "Send reset email" }}
              <Loader v-if="loading" size="1.5" />
            </button>
          </div>
          <div v-if="loginModalState == LoginModalState.Login">
            <span
              style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
              class="ph-no-capture"
              @click="
                () => {
                  toggleLoginSignup();
                  track('login_modal', 'toggle_login_signup', 'click');
                }
              "
            >
              No account? Sign Up</span
            >
            <button
              type="button"
              class="btn btn-info ph-no-capture"
              :disabled="loading"
              @click="login()"
              @keydown.enter="login(true)"
              style="height: 2.5rem; width: 5rem"
            >
              {{ loading ? "" : "Log In" }}
              <Loader v-if="loading" size="1.5" />
            </button>
          </div>
          <div
            v-if="loginModalState == LoginModalState.Signup"
            style="
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
                class="ph-no-capture"
                @click="
                  () => {
                    toggleLoginSignup();
                    track('login_modal', 'toggle_signup_login', 'click');
                  }
                "
              >
                Have an account? Log In</span
              >
              <button
                type="button"
                class="btn btn-info ph-no-capture"
                :disabled="!passwordValid || !passwordRetypeValid || loading"
                @click="signup()"
                @keydown.enter="signup(true)"
                style="height: 2.5rem; width: 5rem"
              >
                {{ loading ? "" : "Sign Up" }}
                <Loader v-if="loading" size="1.5" />
              </button>
            </div>
            <div style="font-size: 0.8rem">
              By signing up you agree to our
              <a
                href="/tos"
                class="ph-no-capture"
                target="_blank"
                @click="track('login_modal', 'terms', 'click')"
                >Terms of Service</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, type Ref, onMounted } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useModalsStore } from "@/stores/modalsStore";
import { LoginModalState } from "@/types/internaltypes";
import { Modal } from "bootstrap";
import Loader from "@/components/util/Loader.vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { track } from "@/util/tracking";
import PostHog from "posthog-js";

const router = useRouter();
const ms = useModalsStore();

const { loginModalState } = storeToRefs(ms);

// Bit of a hacky solution to listen to the modal closing and resetting the values if so
const modalElement = ref(null);
onMounted(() => {
  new Modal(modalElement.value);
  if (modalElement.value != null) {
    // @ts-ignore
    modalElement.value?.addEventListener("hidden.bs.modal", resetValues);
    // @ts-ignore
    modalElement.value?.addEventListener("shown.bs.modal", focusEmailInput);
  }
});

let newsletterConsent = ref<boolean>(false);
const loading = ref(false);
const emailInputLogin = ref(null);
const emailInputSignup = ref(null);
const emailInputReset = ref(null);
let showPassword = ref(false);

watch(emailInputLogin, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputLogin.value?.focus();
  }
});

watch(emailInputSignup, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputSignup.value?.focus();
  }
});

watch(emailInputReset, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputReset.value?.focus();
  }
});

const focusEmailInput = () => {
  document.getElementById("emailInput")!.focus();
};

const userStore = useUserStore();

watch(
  () => ms.loginModalState,
  () => {
    focusEmailInput();
  }
);

let email: Ref<string> = ref("");
let password: Ref<string> = ref("");
let passwordRetype: Ref<string> = ref("");
let passwordValid: Ref<boolean> = ref(false);
let passwordRetypeValid: Ref<boolean> = ref(false);
let emailErrorText: Ref<string> = ref("");
let passwordErrorText: Ref<string> = ref("");
let fullErrorText: Ref<string> = ref("");
let badLoginText = "Wrong e-mail or password";

const resetValues = () => {
  ms.loginModalState = LoginModalState.Login;
  email.value = "";
  password.value = "";
  passwordRetype.value = "";
  passwordValid.value = false;
  passwordRetypeValid.value = false;
  emailErrorText.value = "";
  passwordErrorText.value = "";
  fullErrorText.value = "";
  newsletterConsent.value = false;
};

watch(password, (value) => {
  if (passwordErrorText.value == badLoginText) {
    passwordErrorText.value = "";
  }

  if (value.length == 0 || ms.loginModalState == LoginModalState.Login) {
    // Don't validate on login (or if the user hasn't started typing yet)
    document.getElementById("passwordInput")?.classList.remove("is-valid");
    document.getElementById("passwordInput")?.classList.remove("is-invalid");
    passwordValid.value = false;
    passwordErrorText.value = "";
  } else if (value.length >= 6) {
    document.getElementById("passwordInput")?.classList.add("is-valid");
    document.getElementById("passwordInput")?.classList.remove("is-invalid");
    passwordValid.value = true;
    passwordErrorText.value = "";
  } else {
    document.getElementById("passwordInput")?.classList.add("is-invalid");
    document.getElementById("passwordInput")?.classList.remove("is-valid");
    passwordValid.value = false;
    passwordErrorText.value = "Must be at least 6 characters long";
  }
});

watch(passwordRetype, (value) => {
  if (passwordErrorText.value == badLoginText) {
    passwordErrorText.value = "";
  }

  if (value.length == 0) {
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-valid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-invalid");
    passwordRetypeValid.value = false;
    passwordErrorText.value = "";
  } else if (value == password.value) {
    document.getElementById("passwordInputRetype")?.classList.add("is-valid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-invalid");
    passwordRetypeValid.value = true;
    passwordErrorText.value = "";
  } else {
    document.getElementById("passwordInputRetype")?.classList.add("is-invalid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-valid");
    passwordRetypeValid.value = false;
    passwordErrorText.value = "Passwords doesn't match";
  }
});

const resetPassword = () => {
  loading.value = true;

  userStore
    .resetPassword(email.value)
    .then(() => {
      ms.loginModalState = LoginModalState.ResetSent;
      loading.value = false;
    })
    .catch((error) => {
      console.error("Problem when resetting password", error);
      loading.value = false;
    });
};

const setType = (newType: LoginModalState) => {
  ms.loginModalState = newType;
  focusEmailInput();
};

const doneWithModal = (newUser: boolean) => {
  ms.closeCurrentModal();
  loading.value = false;

  if (newUser) {
    // New users go to the intro game
    localStorage.setItem("gamesettings_flash", "0");

    router.push({
      name: "dashboardsignedup",
    });
  } else {
    // Returning users just go to the dashboard
    router.push({
      name: "dashboard",
    });
  }
};

const login = (usedEnterKey: boolean = false) => {
  loading.value = true;

  userStore
    .signin(email.value, password.value)
    .then(() => {
      track(
        "login_modal",
        "login_success",
        usedEnterKey ? "keydown_enter" : "click"
      );
      doneWithModal(false);
    })
    .catch((error) => {
      if (error.code == "auth/invalid-login-credentials") {
        track(
          "login_modal",
          "login_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Invalid credentials",
          }
        );
        passwordErrorText.value = badLoginText;
        loading.value = false;
      } else {
        console.error("Unexpected error logging in", error);
        track(
          "login_modal",
          "login_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Unexpected error: " + error,
          }
        );
        passwordErrorText.value = "Unexpected error, try again later";
      }
      loading.value = false;
    });
};

const toggleLoginSignup = () => {
  email.value = "";
  password.value = "";
  passwordRetype.value = "";
  ms.loginModalState =
    ms.loginModalState == LoginModalState.Login
      ? LoginModalState.Signup
      : LoginModalState.Login;
  focusEmailInput();
};

const signup = (usedEnterKey: boolean = false) => {
  if (!passwordValid.value || !passwordRetypeValid.value) {
    track(
      "login_modal",
      "signup_failure",
      usedEnterKey ? "keydown_enter" : "click",
      {
        error: "Invalid input",
        passwordValid: passwordValid.value,
        passwordRetypeValid: passwordRetypeValid.value,
      }
    );
    return;
  }

  loading.value = true;

  userStore
    .signup(email.value, password.value, newsletterConsent.value)
    .then(() => {
      doneWithModal(true);
      track(
        "login_modal",
        "signup_success",
        usedEnterKey ? "keydown_enter" : "click"
      );
    })
    .catch((error) => {
      if (error.code == "auth/email-already-in-use") {
        passwordErrorText.value = "Account already exists, login instead";
        track(
          "login_modal",
          "signup_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Account already exists",
          }
        );
      } else {
        console.error("Problem with signing up", error);
        track(
          "login_modal",
          "signup_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: error,
          }
        );
      }
      loading.value = false;
    });
};
</script>

<style scoped>
.position-in-input {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  cursor: pointer;
  color: var(--clr-accent);
}

.extra-margin {
  right: 2rem;
}
</style>
