<template>
  <div
    class="time-container card bounce-top"
    style="z-index: 1000; margin-top: 1rem"
  >
    <div class="time-buttons-container">
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('1', '0');
          }
        "
      >
        <span class="time-button-text">1 + 0</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Bullet</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('1', '1');
          }
        "
      >
        <span class="time-button-text">1 + 1</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Bullet</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('2', '1');
          }
        "
      >
        <span class="time-button-text">2 + 1</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Bullet</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('3', '0');
          }
        "
      >
        <span class="time-button-text">3 + 0</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Blitz</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('3', '2');
          }
        "
      >
        <span class="time-button-text">3 + 2</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Blitz</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('5', '0');
          }
        "
      >
        <span class="time-button-text">5 + 0</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Blitz</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('10', '0');
          }
        "
      >
        <span class="time-button-text">10 + 0</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Rapid</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('10', '5');
          }
        "
      >
        <span class="time-button-text">10 + 5</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Rapid</span
        >
      </button>
      <button
        class="btn btn-info time-button ph-no-capture"
        @click="
          () => {
            setAndSend('15', '10');
          }
        "
      >
        <span class="time-button-text">15 + 10</span><br /><span
          style="font-size: 0.8rem; opacity: 0.7"
          >Rapid</span
        >
      </button>
    </div>
    <div style="display: flex">
      <button
        class="btn btn-warning time-button ph-no-capture"
        @click="
          () => {
            setAndSendNoTimecontrol();
          }
        "
      >
        <span class="time-button-text">None</span>
      </button>
      <div
        style="
          width: 1px;
          align-self: stretch;
          background-color: black;
          margin: 0 1rem;
        "
      ></div>
      <div style="display: flex; flex-direction: column; align-items: center">
        <div>
          <span style="opacity: 0.7">Custom</span>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;
          "
        >
          <div>
            <input
              type="text"
              id="minutes"
              placeholder="Min"
              v-model="customMinutes"
            />
          </div>
          <div>
            <input
              type="text"
              id="increment"
              placeholder="Inc"
              v-model="customIncrement"
            />
          </div>
          <button
            class="btn btn-info btn-sm ph-no-capture"
            @click="
              () => {
                setAndSend(customMinutes, customIncrement);
              }
            "
          >
            <i class="fa-solid fa-arrow-right" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageStore } from "@/stores/pageStore";
import { ref } from "vue";

let initialTimeControl = usePageStore().gameSettings?.timeControl
  ? usePageStore().gameSettings?.timeControl
  : null;

let minutes = ref<string>(
  initialTimeControl ? initialTimeControl.initial / 60 / 1000 + "" : ""
);
let increment = ref<string>(
  initialTimeControl ? initialTimeControl.increment / 1000 + "" : ""
);

// Init values if current minutes and increment evaluates to Custom (they do that if initial is null too, but then it gets set to empty string which is what we want)
let customMinutes = ref(getButtonCategory() === "Custom" ? minutes.value : "");
let customIncrement = ref(
  getButtonCategory() === "Custom" ? increment.value : ""
);

const emit = defineEmits(["update"]);

function setAndSendNoTimecontrol() {
  usePageStore().updateGameSettings(null, undefined, undefined);

  emit("update", null);
}

function setAndSend(newMinutes: string, newIncrement: string) {
  const timeControl = {
    initial: parseInt(newMinutes) * 60 * 1000,
    increment: parseInt(newIncrement) * 1000,
  };

  usePageStore().updateGameSettings(timeControl, undefined, undefined);

  emit("update", timeControl);
}

function getButtonCategory() {
  if (minutes.value === "1" && increment.value === "0") {
    return "Bullet";
  } else if (minutes.value === "1" && increment.value === "1") {
    return "Bullet";
  } else if (minutes.value === "2" && increment.value === "1") {
    return "Bullet";
  } else if (minutes.value === "3" && increment.value === "0") {
    return "Blitz";
  } else if (minutes.value === "3" && increment.value === "2") {
    return "Blitz";
  } else if (minutes.value === "5" && increment.value === "0") {
    return "Blitz";
  } else if (minutes.value === "10" && increment.value === "0") {
    return "Rapid";
  } else if (minutes.value === "10" && increment.value === "5") {
    return "Rapid";
  } else if (minutes.value === "15" && increment.value === "10") {
    return "Rapid";
  } else {
    return "Custom";
  }
}
</script>

<style scoped>
.inactive:not(:checked) + .inactive {
  background-color: var(--clr-main-lightest);
}

.time-buttons-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5rem;
}

.title {
  display: flex;
  gap: 0.2rem;
  justify-content: center;
}

.time-container {
  display: flex;
  gap: 1rem;
  justify-content: start;
  padding: 1rem;
}

.time-container input {
  width: 4ch;
}

.time-button {
  width: 4rem;
  height: 4rem;
}

.time-button-text {
  font-size: 1rem;
  white-space: nowrap;
}
</style>
