<template>
  <div>
    <h2 class="c4" id="h.mlvpxhi97c4t">
      <span class="c22">Terms of Service - Chessiverse.com</span>
    </h2>
    <h3 class="c6 c21" id="h.dj3tjvsnfgvx">
      <span class="c5">1. About Our Terms</span>
    </h3>
    <p class="c9">
      <span class="c16">TL;DR:</span
      ><span class="c0"
        >&nbsp;Read these terms before using Chessiverse. By using the platform,
        you agree to comply with these terms and related policies. Contact us
        with any questions.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.1 These terms explain how you may use the Chessiverse platform,
        hereinafter collectively called &ldquo;Chessiverse&rdquo;.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.2 You should read these terms carefully before using
        Chessiverse.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.3 By accessing or using Chessiverse or otherwise indicating your
        consent, you agree to be bound by these terms and the documents referred
        to in them.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.4 If you do not agree with or accept any of these terms, you should
        stop using Chessiverse immediately.</span
      >
    </p>
    <p class="c2">
      <span
        >1.5 If you have any questions about Chessiverse, please email us at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c2">
      <span class="c0"
        >1.6 Chessiverse is provided by us, Chessiverse AB., with the registered
        office at Fleminggatan 21, 112 26, Stockholm, Sweden.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.7 Your use of Chessiverse means that you agree with and must comply
        with our Acceptable Use Policy, our Privacy Policy, our Cookie Policy,
        and our Subscription Terms.</span
      >
    </p>
    <h3 class="c21" id="h.da69rfq7utdn">
      <span class="c5">2. Using Chessiverse</span>
    </h3>
    <p class="c9">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Use Chessiverse for personal, non-commercial purposes. Keep your
        account details confidential. You&#39;re responsible for your account
        activity. We may suspend your access if terms are violated.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >2.1 Chessiverse is for your personal and non-commercial use only.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >2.2 You have the ability to create an account on Chessiverse by
        providing an email address and a password. You must treat them as
        confidential and not disclose them to any third party.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >2.2.1 We have the right to disable any user identification code or
        password if, in our reasonable opinion, you have failed to comply with
        any of the provisions of these terms.</span
      >
    </p>
    <p class="c2">
      <span
        >2.2.2 If you know or suspect that anyone other than you knows your
        username and password, you must promptly notify us at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c2">
      <span class="c0"
        >2.3 You agree that you are solely responsible for all activity on your
        Chessiverse account, including:</span
      >
    </p>
    <ul class="c13 lst-kix_g76at0jii8sz-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >all costs and expenses you may incur in relation to your use of
          Chessiverse, including purchases of access to digital content and
          subscription fees; and</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >keeping your password and other account details confidential.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >2.4 We may prevent or suspend your access to Chessiverse if you do not
        comply with any part of these terms.</span
      >
    </p>
    <p class="c2">
      <span
        >2.5 You can ask us to delete your account at any time by email to </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <h2 class="c4" id="h.7qcaamx2bmfw">
      <span class="c5"
        >3. Ownership, Use, and Intellectual Property Rights</span
      >
    </h2>
    <p class="c9">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We own the content and intellectual property on Chessiverse. You
        can&#39;t use our trademarks or reproduce our content without
        permission.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.1 Chessiverse and all intellectual property rights in it (including
        any digital content) are owned by us or the owners of the content.
        Intellectual property rights include but are not limited to rights such
        as copyright, trademarks, domain names, design rights, database rights,
        and patents. We reserve all of our rights in any intellectual property
        in connection with these terms.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.2 Nothing in these terms grants you any legal rights in Chessiverse
        other than as necessary to enable you to access Chessiverse. You agree
        not to adjust, try to circumvent, or delete any notices contained on
        Chessiverse (including any intellectual property notices) and in
        particular any digital rights or other security technology embedded or
        contained within Chessiverse.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.3 Trademarks: &ldquo;Chessiverse&rdquo; and the Chessiverse logo are
        our trademarks. Other trademarks and trade names may also be used on
        Chessiverse. The use of any trademarks on Chessiverse is not allowed
        unless you have our prior written permission.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.4 You must not reproduce or make available any part of Chessiverse
        (including any digital content) online or to any other person in any way
        without our explicit prior email permission.</span
      >
    </p>
    <h2 class="c4" id="h.tsny75c3d5md">
      <span class="c5"
        >4. Reporting Infringements of Intellectual Property Rights on
        Chessiverse</span
      >
    </h2>
    <p class="c9">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Notify us if you find any content on Chessiverse that infringes
        on intellectual property rights.</span
      >
    </p>
    <p class="c2">
      <span
        >4.1 We do what we reasonably can to ensure that we do not include on
        Chessiverse any content which infringes the intellectual property rights
        of other people. In the event that you consider any content on
        Chessiverse to infringe your copyright or other intellectual property
        rights, please notify us at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c2">
      <span class="c0"
        >4.2 If you have a complaint regarding any digital content or any
        content uploaded or posted to Chessiverse by any user, please notify us
        and we will deal with it promptly.</span
      >
    </p>
    <h2 class="c4" id="h.qd5l7bsh7be">
      <span class="c8">5</span
      ><span class="c5"
        >. Accuracy of Information and Availability of Chessiverse</span
      >
    </h2>
    <p class="c9">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We strive to keep Chessiverse accurate and available but
        can&#39;t guarantee it will always be bug-free or accessible.</span
      >
    </p>
    <p class="c2">
      <span>5</span
      ><span class="c0"
        >.1 While we try to make sure that Chessiverse is accurate, up-to-date,
        and free from bugs, we cannot promise that it will be. We take pride in
        the quality of the chess content on Chessiverse, but ultimately any
        reliance that you may place on the information on Chessiverse is at your
        own risk.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >5.2 We love working to make Chessiverse widely available and will do
        everything we can to keep it going.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >5.3 You may have certain legal rights when using Chessiverse. These are
        also known as &lsquo;statutory rights&rsquo;.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >5.4 While we try to make sure that Chessiverse is available for your
        use, we do not promise that Chessiverse is available at all times nor do
        we promise the uninterrupted use by you of Chessiverse.</span
      >
    </p>
    <h2 class="c4" id="h.yrgiit3bgdiw">
      <span class="c8">6</span
      ><span class="c5">. Hyperlinks and Third-Party Sites</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We link to third-party sites for convenience but aren&#39;t
        responsible for their content.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Chessiverse may contain links or references to third-party websites
        other than Chessiverse. Those links or references are provided for your
        convenience only. We have no control over third-party websites and
        accept no legal responsibility for anything contained in them. The
        display of any link and reference to any third-party website does not
        mean that we endorse that third party. Your use of a third-party site
        may be governed by the terms and conditions of that third-party
        site.</span
      >
    </p>
    <h2 class="c4" id="h.z0pa51spsbq0">
      <span class="c8">7</span
      ><span class="c5">. Limitation on Our Liability</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We&#39;re not liable for unforeseeable losses or business-related
        losses.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Except for any legal responsibility that we cannot exclude in law (such
        as for death or personal injury), we are not legally responsible for any
        losses that were not foreseeable to you and us when these terms were
        formed, or that were not caused by any breach on our part. Neither are
        we responsible for business losses and losses to non-consumers.</span
      >
    </p>
    <h2 class="c29" id="h.z00xwu9hke1j">
      <span class="c8">8</span
      ><span class="c5">. Events Beyond Our Control</span>
    </h2>
    <p class="c2">
      <span class="c0"
        >We will have no liability to you for any breach of these terms caused
        by any event or circumstance beyond our reasonable control.</span
      >
    </p>
    <h2 class="c4" id="h.1c5nwzoo07zh">
      <span class="c8">9</span><span class="c5">. Rights of Third Parties</span>
    </h2>
    <p class="c2">
      <span class="c0"
        >No one other than a party to these terms has any right to enforce any
        of these terms.</span
      >
    </p>
    <h2 class="c4" id="h.wxbu6djj5y3f">
      <span class="c8">10</span><span class="c5">. Variation</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We can change these terms, and by continuing to use Chessiverse,
        you agree to the new terms.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >No changes you propose to these terms are valid or have any effect
        unless we agree to them in writing. We reserve the right to vary these
        terms from time to time. Our updated terms will be displayed at this URL
        on Chessiverse, and we will notify you by email if you have provided it
        to us. By continuing to use and access Chessiverse following such
        changes, you agree to be bound by any variation made by us. It is your
        responsibility to check these terms from time to time to verify such
        variations.</span
      >
    </p>
    <h2 class="c4" id="h.1em30h40xljw">
      <span class="c8">11</span><span class="c5">. Assignment</span>
    </h2>
    <p class="c2">
      <span class="c0"
        >We may transfer or assign your account to a sister, parent or
        subsidiary company, or to a company that buys our business or a portion
        of it. For example, we may transfer or assign your account to a company
        in the Chessiverse group of companies.</span
      >
    </p>
    <h2 class="c4" id="h.5blxneap4sel"><span class="c5">12. Disputes</span></h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We&#39;ll try to resolve disputes amicably. Swedish law applies.
        Respectful communication is expected.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >12.1 We put a high value on good relations with you and the chess
        community in general. We will try to resolve any disputes with you
        quickly and efficiently.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >12.2 If you are unhappy with us, please contact us as soon as
        possible.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >12.3 If you and we cannot resolve a dispute using our complaint
        handling procedure, we will let you know that we cannot settle the
        dispute with you.</span
      >
    </p>
    <p class="c2">
      <span
        >12.4 You may also use the online dispute resolution (ODR) platform to
        resolve the dispute with us. For more details, please visit the website
        on the &lsquo;Your Europe&rsquo; portal.</span
      ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup>
    </p>
    <p class="c2">
      <span class="c0"
        >15.5 Relevant Swedish law will apply to the Terms of Service and the
        Subscription Terms without regard to its conflicts of law principles. If
        you are a consumer resident in the European Union you may benefit from
        any mandatory provisions of the law of your country of residence and if
        you reside in a country in which this clause is prohibited by local law,
        this clause does not apply to you and does not deprive you of the
        protection of the mandatory provisions of the consumer protection laws
        in your country. Nothing in any of these Chessiverse Terms of Service
        affects your rights as a consumer to rely on such mandatory provisions
        of local law.</span
      >
    </p>
    <p class="c2">
      <span
        >15.6 We expect our staff to be polite and respectful when dealing with
        you. We expect you to be respectful of them too. If you are abusive to
        our staff, or make any threats to our staff&rsquo;s safety or well-being
        (whether inside or outside of Chessiverse communication tools), we may
        decide to remove your ability to communicate with us using
        Chessiverse.</span
      >
    </p>
    <hr />
    <h2 class="c2 c6" id="h.ejd50qi53w6z">
      <span class="c22">Chessiverse Subscription Terms</span>
    </h2>
    <h2 class="c4" id="h.24u4ao5wspho">
      <span class="c8">1</span
      ><span class="c5"
        >. Subscriptions, Paid Features, Pricing And Availability</span
      >
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Subscription terms explain the benefits and features you get for
        a fee. We may change prices or features but will inform you in
        advance.</span
      >
    </p>
    <p class="c2">
      <span>1</span
      ><span class="c0"
        >.1 These Subscription Terms set out the terms under which certain
        benefits and features (&ldquo;Paid Features&rdquo;) accessible for a fee
        on a subscription basis (&ldquo;Subscription&rdquo;), is sold by us
        through Chessiverse. Some of the subscriptions are renewable; others are
        indefinite or time-limited. We will display the basis on which your
        subscription is offered before you purchase it.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.2 We may from time to time change our prices. Changes in price will
        not affect any Subscription that you have already purchased but will
        apply to any subsequent renewal or new Subscription. We will inform you
        of any change in price at least 14 days before the change is due to take
        effect. If you do not agree to such a change, you may cancel the
        Subscription as described in section 8.1.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.3 Minor changes may, from time to time, be made to certain paid
        features, for example, to reflect changes in relevant laws and
        regulatory requirements, or to address technical or security issues or
        rights restrictions. These changes will not alter the main
        characteristics of the Paid Features and should not normally affect your
        use of that Paid Feature. However, if any change is made that would
        affect your use of the paid features, suitable information will be
        provided to you.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.4 Where any updates are made to paid features, that Paid Feature will
        continue to match our description of it as provided to you before you
        purchased your Subscription to access the Paid Feature. Please note that
        this does not prevent us from enhancing the paid features, thereby going
        beyond the original description.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >1.5 We make all reasonable efforts to ensure that all prices shown on
        Chessiverse are correct at the time of going online.</span
      >
    </p>
    <h2 class="c4" id="h.i5y80vssnibn">
      <span class="c8">2</span><span class="c5">. Orders</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Follow the steps to purchase a subscription. Ensure your
        information is accurate. If there&#39;s an issue, we&#39;ll contact you
        or refund your payment.</span
      >
    </p>
    <p class="c2">
      <span>2</span
      ><span class="c0"
        >.1 Chessiverse will guide you through the process of purchasing a
        Subscription. Before completing your purchase, you will be given the
        opportunity to review your order and amend it. Please ensure that you
        have checked your order carefully before submitting it.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >2.2 If, during the order process, you provide us with incorrect or
        incomplete information, please contact us as soon as possible. If we are
        unable to process your order due to incorrect or incomplete information,
        we will contact you to ask you to correct it. If you do not give us the
        accurate or complete information within a reasonable time of our
        request, we will cancel your order. We will not be responsible for any
        delay in the availability of paid features that results from you
        providing incorrect or incomplete information.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >2.3 In the unlikely event that we do not accept or cannot fulfil your
        order for any reason, we will explain why by email. No payment will be
        taken under normal circumstances. If we have taken payment, any such
        sums will be refunded to you as soon as possible and in any event within
        14 days.</span
      >
    </p>
    <h2 class="c4" id="h.fjjge0mkf4ua">
      <span class="c8">3</span><span class="c5">. Payment</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Pay for subscriptions in advance. If you miss a payment, access
        may be suspended. Contact us for billing issues.</span
      >
    </p>
    <p class="c2">
      <span>3</span
      ><span class="c0"
        >.1 Payment for Subscriptions must always be made in advance. Your
        chosen payment method will be charged when we process your order and
        send you a Subscription Confirmation by email (this usually occurs
        immediately and you will be shown a message confirming your
        payment).</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.2 We, via our payment provider Stripe, accept various payment methods
        depending on your location. All available payment methods are displayed
        during checkout.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >3.3 If you do not make any payment due to us on time, we will suspend
        your access to the paid features. For more information, please refer to
        section 4.4. If you do not make payment within 7 days of our reminder,
        we may cancel the Subscription. Any outstanding sums due to us will
        remain due and payable.</span
      >
    </p>
    <p class="c2">
      <span
        >3.4 If you believe that we have charged you an incorrect amount, please
        contact us at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0"
        >&nbsp;as soon as reasonably possible to let us know. You will not be
        charged for paid features while availability is suspended.</span
      >
    </p>
    <h2 class="c4" id="h.s95if7893m54">
      <span class="c8">4</span
      ><span class="c5">. Provision of Paid Features</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Paid features are available immediately after confirmation. We
        may suspend features for technical reasons or non-payment. Serious
        breaches may result in suspension or termination.</span
      >
    </p>
    <p class="c2">
      <span>4</span
      ><span class="c0"
        >.1 Paid Features appropriate to your Subscription will be available to
        you immediately when we send you a Subscription Confirmation and will
        continue to be available for the duration of your Subscription
        (including any renewals).</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >4.2 In some limited circumstances, we may need to suspend the provision
        of paid features (in full or in part) for one or more of the following
        reasons:</span
      >
    </p>
    <ul class="c13 lst-kix_dpjceqioj6io-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >To fix technical problems or to make necessary minor technical
          changes; or</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >To update the paid features to comply with relevant changes in the
          law, the requirements of anyone holding rights to the paid features,
          or other regulatory requirements.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >4.3 If we need to suspend availability of the paid features for any of
        these reasons, we will inform you in advance of the suspension and
        explain why it is necessary. If the suspension lasts (or we tell you
        that it is going to last) for more than 7 days, you may end the
        Subscription as described below in section 8.2.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >4.4 We may suspend provision of the paid features if we do not receive
        payment on time from you. We will inform you of the non-payment on the
        due date, however if you do not make payment within 7 days of our
        notice, we may suspend provision of the paid features until we have
        received all outstanding sums due from you. If we do suspend provision
        of the paid features, we will inform you of the suspension. You will not
        be charged for any paid features while provision is suspended.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >4.5 We may suspend or terminate your Subscription if you breach
        Chessiverse terms of use (including these Subscription Terms). We value
        our engagement with the chess community and will make every effort to be
        sensible and proportionate when considering any breaches.</span
      >
    </p>
    <h2 class="c4" id="h.dmgjxxuv8ty7">
      <span class="c8">5</span
      ><span class="c5">. Renewal of Your Subscription</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Subscriptions renew automatically unless you cancel before the
        renewal date.</span
      >
    </p>
    <p class="c2">
      <span
        >If we have indicated to you at the time of purchase that your
        Subscription is one which is on an auto-renew basis, it will renew
        automatically until the end of the subscription period, unless you
        notify us prior to the renewal by email to </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0"
        >&nbsp;that you wish to cancel it (or unless terminated earlier in
        accordance with these terms), or arrange with your payment services
        provider to cease payments in respect of the Subscription.</span
      >
    </p>
    <h2 class="c29" id="h.lo3wultustdn">
      <span class="c8">6</span
      ><span class="c5">. Problems with the Paid Features</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;If paid features are faulty, we will repair or replace them. You
        may be entitled to a refund or compensation if we cannot fix the
        problem.</span
      >
    </p>
    <p class="c2">
      <span>6</span
      ><span class="c0"
        >.1 By law, we must provide paid features that are of satisfactory
        quality, fit for purpose, and as described. If any Paid Feature
        available through your Subscription does not comply, please contact us
        as soon as reasonably possible to inform us of the problem. Your
        available remedies will be as follows:</span
      >
    </p>
    <ul class="c13 lst-kix_r1v9qk6jofb9-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >If the Paid Feature has faults, you will be entitled to a repair or a
          replacement.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >If we cannot fix the problem, or if it has not been (or cannot be)
          fixed within a reasonable time and without significant inconvenience
          to you, you may be entitled to a full or partial refund.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >If you can demonstrate that the fault has damaged your device or
          other digital content belonging to you because we have not used
          reasonable care and skill, you may be entitled to a repair or
          compensation. Please refer to section 6.3 for more information.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span
        >6.2 If there is a problem with any Paid Feature, please contact us at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">&nbsp;to inform us of the problem.</span>
    </p>
    <p class="c2">
      <span class="c0"
        >6.3 Refunds (whether full or partial, including reductions in price)
        under this Section 6 will be issued within 14 calendar days of the day
        on which we agree that you are entitled to the refund.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >6.4 Refunds under this Section 6 will be made using the same payment
        method that you used when purchasing your Subscription.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >6.5 For further information on your rights as a consumer, please
        contact your local Citizens&rsquo; Advice Bureau or Trading Standards
        Office.</span
      >
    </p>
    <h2 class="c29" id="h.u4pfp82arpwl">
      <span class="c5">7. Cancelling Your Subscription</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;Cancel during the 7-day trial to avoid charges. After that, no
        refunds, but you retain access until the period ends. Contact us for
        cancellations. Abusing cancellations may lead to refusal of future
        orders.</span
      >
    </p>
    <p class="c2">
      <span>7</span
      ><span class="c0"
        >.1 Subscriptions can be cancelled for any reason during the 7-day free
        trial period. If you cancel within this period, you will not be
        charged.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.2 After the 7-day free trial period, you may cancel your Subscription
        at any time, however, we cannot offer any refunds and you will continue
        to have access to the paid features for the remainder of your current
        Subscription period (up until the renewal or expiry date, as
        applicable), at which time the Subscription will end.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.3 If you allow a Subscription to auto-renew by mistake, please inform
        us as soon as possible. We will be able to cancel the Subscription and
        issue a full refund if informed within 7 days of the renewal.</span
      >
    </p>
    <p class="c2">
      <span
        >7.4 If you wish to exercise your right to cancel under this Section 7,
        you may inform us of your cancellation in any way you wish. For your
        convenience, we offer a contact form on our website. Cancellation by
        email or by post is effective from the date on which you send us your
        message. If you would prefer to contact us directly to cancel, please
        use the following email: </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0"
        >. Be sure to provide us with your name, address, email address, and
        Subscription ID.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.5 We may ask you why you have chosen to cancel and may use any
        answers you provide to improve our content and services. However, please
        note that you are under no obligation to provide any details if you do
        not wish to.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.6 Refunds under this Section 7 will be issued to you as soon as
        possible, and in any event within 14 calendar days of the day on which
        you inform us that you wish to cancel. However, if you purchased the
        subscription through an App store, like Apple, Google, etc., the App
        store will be responsible for handling all refunds and subscription
        changes or cancellations. In those cases, we will not be able to give
        refunds or make changes to subscriptions purchased through the App
        store. You must contact the App store support.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.7 Refunds under this Section 7 will be made using the same payment
        method that you used when purchasing your Subscription.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >7.8 If you repeatedly cancel orders or if we notice behavior that we
        reasonably consider represents an abuse of the cancellation right, we
        may refuse to accept further orders from you. If you ask for a refund
        for the second time over a Subscription, we will refuse the refund in
        itself due to abuse of the cancellation right.</span
      >
    </p>
    <h2 class="c4" id="h.wkmvgflmvx4g">
      <span class="c8">8</span
      ><span class="c5">. Your Other Rights to End the Subscription</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;You can end your subscription if there are significant changes,
        extended feature suspensions, or breaches. You may get a prorated
        refund.</span
      >
    </p>
    <p class="c2">
      <span>8</span
      ><span class="c0"
        >.1 You may end the Subscription at any time if we have informed you of
        a forthcoming change to your Subscription or the paid features (as
        described in section 1.1), or to these Subscription Terms that you do
        not agree to. If the change is set to take effect or apply to you before
        the end of your current Subscription, we will issue you with a prorated
        refund equal to the remaining time left in that Subscription. If the
        change will not take effect or apply to you until the expiry of your
        current Subscription, the Subscription will end at the end of that
        Subscription period and you will continue to have access to the paid
        features until that date.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >8.2 If we have suspended availability of the paid features for more
        than 7 days, or we have informed you that we are going to suspend
        availability for more than 7 days, you may end the Subscription
        immediately, as described in section 4.3. If you end the Subscription
        for this reason, we will issue you with a prorated refund.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >8.3 You also have a legal right to end the Subscription at any time if
        we are in breach of it. You may also be entitled to a full or partial
        refund and compensation. For more details of your legal rights, please
        refer to your local Citizens Advice Bureau or Trading Standards
        Office.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >8.4 Refunds under this Section 8 will be made within 14 calendar days
        of the date on which your cancellation becomes effective, using the same
        payment method that you used when purchasing your Subscription.</span
      >
    </p>
    <h2 class="c4" id="h.ljqa5fdhlx7r">
      <span class="c8">9</span><span class="c5">. Our Liability to You</span>
    </h2>
    <p class="c2">
      <span class="c16">TL;DR:</span
      ><span
        >&nbsp;We&rsquo;re liable for foreseeable losses due to our breach or
        negligence. We aren&#39;t liable for business losses or damage caused by
        not following our instructions.</span
      >
    </p>
    <p class="c2">
      <span>9</span
      ><span class="c0"
        >.1 We will be responsible for any foreseeable loss or damage that you
        may suffer as a result of our breach of these Subscription Terms or as a
        result of our negligence. Loss or damage is foreseeable if it is an
        obvious consequence of our breach or negligence or if it is contemplated
        by you and us when the Subscription is created. We will not be
        responsible for any loss or damage that is not foreseeable.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >9.2 Our paid features are intended for non-commercial use only. We make
        no promise that the paid features are fit for commercial, business, or
        industrial use of any kind. We will not be liable to you for any loss of
        profit, loss of business, interruption to business, or for any loss of
        business opportunity.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >9.3 If, as a result of our failure to exercise reasonable care and
        skill, any digital content (including but not limited to paid features)
        from Chessiverse damages your device or other digital content belonging
        to you, we will either repair the damage or pay you appropriate
        compensation. Please note that we will not be liable under this
        provision if:</span
      >
    </p>
    <ul class="c13 lst-kix_415zajy0e45g-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >The damage has been caused by your own failure to follow our
          instructions; or</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Your device does not meet any relevant minimum system requirements
          that we have made you aware of before you purchased your
          Subscription.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >9.4 Nothing in these Subscription Terms seeks to limit or exclude our
        liability for death or personal injury caused by our negligence
        (including that of our employees, agents, or sub-contractors); or for
        fraud or fraudulent misrepresentation.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >9.5 Nothing in these Subscription Terms seeks to exclude or limit your
        legal rights as a consumer. For more details of your legal rights,
        please refer to your local Citizens Advice Bureau or Trading Standards
        Office.</span
      >
    </p>
    <h2 class="c4" id="h.lbecgorq0hcg">
      <span class="c8">10</span><span class="c5">. Contacting Us</span>
    </h2>
    <p class="c2">
      <span
        >If you wish to contact us with general questions or complaints or to
        cancel your Subscription, you may contact us by email at </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com"
          >support@chessiverse.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c2 c20"><span class="c0"></span></p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
