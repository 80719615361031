import { Chess, type Square } from "chess.js";
import type { Move, Promotion } from "vue3-chessboard";
import type { Puzzle, TimeControl } from "@/types/apitypes";
import * as bootstrap from "bootstrap";

export function timeLeftPerSide(
  startTime: Date,
  times: Date[],
  timeControl: TimeControl
): { white: number; black: number } {
  // Time in seconds that each player initially has
  let whiteTime = timeControl.initial;
  let blackTime = timeControl.initial;

  // Convert startTime to Date object if it's not already
  startTime = startTime instanceof Date ? startTime : new Date(startTime);

  // Convert start time to milliseconds for comparison
  let previousTime = startTime.getTime();

  // Iterate through each timestamp in the moves array
  for (let i = 0; i < times.length; i++) {
    let time = times[i];

    time = time instanceof Date ? time : new Date(time);

    // Calculate the current move time in milliseconds
    const currentTime = time.getTime();

    // Calculate the duration spent for the current move in seconds
    const duration = currentTime - previousTime;

    // Subtract the duration from the respective player's time
    if (i === 0 || i % 2 === 0) {
      // White's move
      whiteTime -= duration;

      if (whiteTime < 0) {
        // White ran out of time, so don't add increment, and set to 0. We're done now
        whiteTime = 0;
        break;
      } else {
        whiteTime += timeControl.increment;
      }
    } else {
      // Black's move
      blackTime -= duration;
      if (blackTime < 0) {
        blackTime = 0;
        break;
      } else {
        blackTime += timeControl.increment;
      }
    }

    // Update the previousTime for the next iteration
    previousTime = currentTime;
  }

  return { white: whiteTime, black: blackTime };
}

export function timeLeftUntilString(targetDateIsoString: string): string {
  // Calculate time difference in milliseconds
  const now = Date.now();
  const targetTime = new Date(targetDateIsoString).getTime();
  let diff = targetTime - now;

  if (diff < 0) {
    // The target date is in the past
    return "Now";
  }

  // Constants for time units in milliseconds
  const MINUTE = 60 * 1000;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;

  // Calculate time units
  const weeks = Math.floor(diff / WEEK);
  diff -= weeks * WEEK;

  const days = Math.floor(diff / DAY);
  diff -= days * DAY;

  const hours = Math.floor(diff / HOUR);
  diff -= hours * HOUR;

  const minutes = Math.floor(diff / MINUTE);

  // Build output string
  let output = "";
  if (weeks > 0) {
    output += `${weeks}w `;
  }
  if (days > 0) {
    output += `${days}d `;
  }
  if (hours > 0) {
    output += `${hours}h `;
  }
  if (minutes > 0) {
    output += `${minutes}m`;
  }

  // Trim any trailing spaces and return
  return output.trim() || "Now";
}

// This doesn't work dynamically when resizing the window, have to reload for it to change (should be fine)
export const isMobileWidth = () => {
  const mobileBreakpoint = 992;
  return window.innerWidth < mobileBreakpoint;
};

export const isFullWidth = () => {
  return window.innerWidth >= 1200;
};

export function hexToRgba(hex: string, alpha: number = 1): string {
  // Ensure the hex string is valid
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error("Invalid HEX color.");
  }

  let c: string[];

  if (hex.length === 4) {
    c = hex.substr(1).split("");
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  } else {
    c = hex.substr(1).split("");
  }

  const r = parseInt(c[0] + c[1], 16);
  const g = parseInt(c[2] + c[3], 16);
  const b = parseInt(c[4] + c[5], 16);

  return `rgba(${r},${g},${b},${alpha})`;
}

/* hexToComplimentary : Converts hex value to HSL, shifts
 * hue by 180 degrees and then converts hex, giving complimentary color
 * as a hex value
 * @param  [String] hex : hex value
 * @return [String] : complimentary color as hex value
 */
export function hexToComplimentary(hex: string) {
  // Convert hex to rgb
  // Credit to Denis http://stackoverflow.com/a/36253499/4939630
  let rgb: string =
    "rgb(" +
    (hex = hex.replace("#", ""))
      .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))!
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .join(",") +
    ")";

  // Get array of RGB values
  const rgbArray: string[] = rgb.replace(/[^\d,]/g, "").split(",");

  let r: number = parseInt(rgbArray[0]);
  let g: number = parseInt(rgbArray[1]);
  let b: number = parseInt(rgbArray[2]);

  // Convert RGB to HSL
  // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
  r /= 255.0;
  g /= 255.0;
  b /= 255.0;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2.0;
  let s = (max + min) / 2.0;
  const l = (max + min) / 2.0;

  if (max == min) {
    h = s = 0; //achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min);

    if (max == r && g >= b) {
      h = (1.0472 * (g - b)) / d;
    } else if (max == r && g < b) {
      h = (1.0472 * (g - b)) / d + 6.2832;
    } else if (max == g) {
      h = (1.0472 * (b - r)) / d + 2.0944;
    } else if (max == b) {
      h = (1.0472 * (r - g)) / d + 4.1888;
    }
  }

  h = (h / 6.2832) * 360.0 + 0;

  // Shift hue to opposite side of wheel and convert to [0-1] value
  h += 180;
  if (h > 360) {
    h -= 360;
  }
  h /= 360;

  // Convert h s and l values into r g and b values
  // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  r = Math.round(r * 255);
  g = Math.round(g * 255);
  b = Math.round(b * 255);

  // Convert r b and g values to hex
  rgb = (b | (g << 8) | (r << 16)).toString();
  return "#" + (0x1000000 | parseInt(rgb)).toString(16).substring(1);
}

export const shortFen = function (longFen: String) {
  const fenSplit = longFen.split(" ");
  let fen =
    fenSplit[0] + " " + fenSplit[1] + " " + fenSplit[2] + " " + fenSplit[3];

  // If there is an en passant square in the fen, check if there's actually an en passant
  // move to be played. If not, just remove the en passant square and treat this position the
  // same as other move orders
  if (!fen.endsWith("-")) {
    let hasEp = false;

    const game = new Chess(fen + " 0 1"); // The stupid library requires numbers...

    // Options square as parameter because it's from square, not to square
    const legalMovesToEpSquare = game.moves();

    for (let i = 0; i < legalMovesToEpSquare.length; i++) {
      if (legalMovesToEpSquare[i].includes("x" + fen.slice(-2))) {
        hasEp = true;
        break;
      }
    }

    if (!hasEp) {
      // No en passant in the position, so replace the last to characters (e.g. e3) with - (no ep)
      fen = fen.substring(0, fen.length - 2) + "-";
    }
  }

  return fen;
};

export const uciMoveToMove = function (uciMoveString: String) {
  const from = uciMoveString.substring(0, 2) as Square;
  const to = uciMoveString.substring(2, 4) as Square;

  // Extract promotion (with and without equal sign)
  let promotion = "";
  if (uciMoveString.length === 5) {
    promotion = uciMoveString.substring(4, 5);
  } else if (uciMoveString.length === 6) {
    promotion = uciMoveString.substring(5, 6);
  }

  const makeMove: Move = {
    from: from,
    to: to,
    promotion: promotion !== "" ? (promotion as Promotion) : undefined,
  };

  return makeMove;
};

export function dateToDateMonthWithOptionalYear(
  date: string | Date | null | undefined,
  showYear: boolean = false
): string {
  if (date == null || date === "") {
    return "-";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  if (showYear) {
    // @ts-ignore
    return `${month} ${day.toString().padStart(2, "0")}, ${year}`;
  } else {
    // @ts-ignore
    return `${month} ${day.toString().padStart(2, "0")}`;
  }
}

export function formatDateTime(input: string | null | undefined): string {
  if (input == null || input === "") {
    return "-";
  }

  return new Date(input).toLocaleString();
}

export function puzzleToPgn(puzzle: Puzzle | null) {
  if (puzzle == null) {
    return "";
  }

  const game = new Chess(puzzle.fen);
  const moves = puzzle.moves.split(" ");
  for (let i = 0; i < moves.length; i++) {
    game.move(moves[i]);
  }

  return game.pgn();
}

export function getFlagUrl(countryCode: string) {
  if (countryCode == "unknown") {
    countryCode = "xx";
  }

  return "https://flagicons.lipis.dev/flags/4x3/" + countryCode + ".svg";
}

export function getViewportSize(): { width: number; height: number } {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function deepEqual(x: any, y: any): boolean {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

export function timeControlToString(timeControl: TimeControl): string {
  return `${timeControl.initial}+${timeControl.increment}`;
}

export function parseDate(dateString: string | null | undefined): Date | null {
  if (!dateString) {
    return null;
  }

  try {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date;
    }
  } catch (error) {
    return null;
  }

  return null;
}
