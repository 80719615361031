<template>
  <div
    style="
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4rem;
      max-width: 60rem;
      margin: 0 auto;
      text-align: center;
    "
  >
    <section class="full-width-section">
      <div>
        <span class="pre-heading">PersonaPlay™</span>
        <h2>
          The Future of
          <span class="accent">Human-like</span> Chess AI
        </h2>
        <div
          class=""
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          "
        >
          <div style="display: flex; align-items: center; gap: 0.5rem">
            <img
              alt="David Kramaley"
              src="https://pbs.twimg.com/profile_images/1514172252828446723/kNV6opmN_400x400.jpg"
              style="
                border: 2px solid black;
                border-radius: 50%;
                width: 5rem;
                height: 5rem;
              "
            />
            <span style="font-weight: 800"
              >David Kramaley, <br />Co-founder of Chessable.com</span
            >
          </div>
          <span style="font-size: 1.2rem"
            ><i
              class="fa-solid fa-quote-left"
              style="color: var(--clr-accent); opacity: 80%" />
            Having a consistent way to practice against opponents that feel
            truly human, is not only a fun, stress-free way to play chess but
            also a great way to improve and get better. PersonaPlay™ at
            Chessiverse does just that. It brings the kind of realistic,
            human-like chess experience that was missing from online platforms.
            Now, players can practice, learn, and refine their strategies
            against bots that think, react, and challenge them just like a real
            opponent would.

            <i
              class="fa-solid fa-quote-right"
              style="color: var(--clr-accent); opacity: 80%"
          /></span>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 2rem;
          "
        >
          <button
            v-if="getUserState() == 'not-logged-in'"
            type="button"
            class="btn btn-info btn-lg"
            @click="
              () => {
                useModalsStore().showLogin(LoginModalState.Signup);
                track('personaplay', 'play_now_button_upper', 'click');
              }
            "
          >
            Play now for Free!
          </button>
          <a
            v-if="getUserState() == 'free'"
            type="button"
            class="btn btn-warning btn-lg"
            href="/premium"
            @click="
              () => {
                track('personaplay', 'go_premium_button_upper', 'click');
              }
            "
          >
            <i class="fa-solid fa-crown" /> Get premium
          </a>
          <img
            v-if="!isMobileWidth()"
            style="max-width: 50rem; width: 100%; margin: 4rem 0"
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay_header.webp"
            alt="PersonaPlay Profiles"
          />
        </div>
      </div>
    </section>

    <section>
      <div>
        <span class="pre-heading">What is PersonaPlay™?</span>
        <h2>
          <span class="accent">Reinventing chess bots</span> from the ground up
        </h2>
        <p>
          At Chessiverse we have a simple goal: to provide an authentic chess
          experience, without the need for human opponents. To achieve this we
          need to take our bots to the next level. Every move in every game
          should make sense, not only from a chess perspective but also from a
          human perspective. That's why we developed
          <strong>PersonaPlay™</strong>, a groundbreaking concept that considers
          every aspect of a chess bot that makes it feel human.
        </p>
        <div class="stats-boxes">
          <div
            style="display: flex; align-items: center; flex-direction: column"
          >
            <div>
              <i class="fa-solid fa-robot accent" style="font-size: 2rem" />
            </div>
            <div style="font-size: 2rem; font-weight: 800">500+</div>
            <div style="font-size: 1.25rem">PersonaPlay™ bots</div>
          </div>
          <div
            style="display: flex; align-items: center; flex-direction: column"
          >
            <div>
              <i
                class="fa-solid fa-book-open-cover accent"
                style="font-size: 2rem"
              />
            </div>
            <div style="font-size: 2rem; font-weight: 800">1000+</div>
            <div style="font-size: 1.25rem">Unique opening books</div>
          </div>
          <div
            style="display: flex; align-items: center; flex-direction: column"
          >
            <div>
              <i class="fa-solid fa-comment accent" style="font-size: 2rem" />
            </div>
            <div style="font-size: 2rem; font-weight: 800">200k+</div>
            <div style="font-size: 1.25rem; max-width: 10rem">
              ChatGPT chats generated (so far)
            </div>
          </div>
          <div
            style="display: flex; align-items: center; flex-direction: column"
          >
            <div>
              <i
                class="fa-solid fa-chess-board accent"
                style="font-size: 2rem"
              />
            </div>
            <div style="font-size: 2rem; font-weight: 800">1+ million</div>
            <div style="font-size: 1.25rem">Calibration games played</div>
          </div>
        </div>
      </div>
    </section>
    <section class="full-width-section">
      <div style="display: flex; flex-direction: column; gap: 4rem">
        <h2>The <span class="accent">Technology</span> Behind PersonaPlay™</h2>
        <p>
          To create a Chessiverse bot with <strong>PersonaPlay™</strong> we
          needed to develop several technologies that together form a human-like
          experience.
        </p>
        <div class="feature-with-image image-left">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/neural.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Carefully selected engines</span
            >
            <h3 class="accent">Neural Networks</h3>
            <span style="font-size: 1.2rem"
              >Every bot has its own unique neural network, selected to match
              playing strength and playing style.</span
            >
          </div>
        </div>
        <div class="feature-with-image image-right">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/openings.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Individually generated</span
            >
            <h3 class="accent">Opening books</h3>
            <span style="font-size: 1.2rem"
              >We generate unique opening books for every bot, based on millions
              of real human games. Each opening book is carefully crafted to
              perfectly match what you would expect from every individual
              bot.</span
            >
          </div>
        </div>
        <div class="feature-with-image image-left">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/behaviours.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Continuously perfected</span
            >
            <h3 class="accent">Behaviours</h3>
            <span style="font-size: 1.2rem"
              >We add a layer of adjustments to further enchance the unique
              character of each bot. Some bots get into time trouble, some are
              likely to blunder more, some never resign and some have weak
              openings. We currently have over
              <span class="accent">30 distinct behaviours</span>.
            </span>
          </div>
        </div>
        <div class="feature-with-image image-right">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/ratings.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Calibrated to level</span
            >
            <h3 class="accent">Real ratings</h3>
            <span style="font-size: 1.2rem"
              >To ensure you can quickly find a bot that's right for you, we
              calibrate each bot's rating against our users and we've simulated
              over a million matches to get the ratings exactly right. At
              Chessiverse there are no inflated ratings to boost egos.</span
            >
          </div>
        </div>
        <div class="feature-with-image image-left">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/personalities.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Make it real</span
            >
            <h3 class="accent">Personalities</h3>
            <span style="font-size: 1.2rem"
              >A playing style only feels authentic if it comes with a
              personality. That's why we've created thousands of images,
              background stories, ChatGPT triggers, quirks and traits of all our
              bots. Taking it that critical extra step to feel real.</span
            >
          </div>
        </div>
        <div class="final-feature-with-image">
          <img
            src="https://storage.googleapis.com/chessiverse1/images/elements/personaplay/blocks.webp"
            style="aspect-ratio: 16/9; border-radius: 0.5rem; margin: 1rem"
          />
          <div>
            <span style="margin-bottom: -0.75rem; opacity: 0.5"
              >Building blocks</span
            >
            <h3 class="accent">The full advantage of bots</h3>
            <span style="font-size: 1.2rem"
              >When you find your favorite bot, you have your perfect training
              partner. We build on this with puzzles, openings, tactical
              positions, famous games and endgames. There's always something you
              can train with your new perfect partner.</span
            >
          </div>
        </div>
      </div>
    </section>
    <section>
      <div style="display: flex; flex-direction: column; gap: 4rem">
        <span class="pre-heading">Let's see an example</span>
        <h2>How <span class="accent">Ethan Snide</span> Plays</h2>
        <div
          style="
            display: flex;
            gap: 2rem;
            flex-direction: column;
            align-items: center;
          "
        >
          <p style="font-size: 1.2rem">
            Let's have a look at one of our favorite bots, Ethan Snide. A
            canadian brat with a quick tongue.
          </p>
          <ProfileCard
            :botMock="{
              name: 'Ethan Snide',
              countryCode: 'ca',
              countryName: 'Canada',
              rating: 169,
              botId: 'UCQPF',
              occupation: 'Obnoxious Kid',
            }"
            :display-occupation="true"
          />
        </div>
        <div
          style="
            display: flex;
            gap: 1rem;
            padding: 1rem 2rem 3rem 2rem;
            flex-wrap: wrap;
            justify-content: center;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span class="accent" style="display: flex; justify-content: center"
              >Playing strength</span
            >
            <span
              >A weak neural net, that frequently hangs pieces. In our tests
              Ethan barely managed to beat any of our other bots.</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Opening book</span
            >
            <span
              >A shallow opening book with no real direction, generated from
              beginner games, making early queen attacks and cheap attacks a
              distinct possibility</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Variety</span
            >
            <span
              >Likely to stick to one opening, but what that might be is not so
              clear.</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Style</span
            >
            <span
              >Chaotic and aggressive. At this level it's hard to see a real
              pattern in play, but rest-assured he'll go after any piece he
              sees.</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span class="accent" style="display: flex; justify-content: center"
              >Time management</span
            >
            <span
              >Generally quick without much thought, but can get stuck on easy
              moves for no reason.</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Will he resign?</span
            >
            <span>Well, no. Unless he's lost most of his pieces.</span>
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Background</span
            >
            <span
              >Young and self-confident, with little to no discipline. This
              would explain his playing style and strength.</span
            >
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-width: 10rem;
            "
          >
            <span
              class="accent"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              >Personality</span
            >
            <span
              >You're likely to get scolded when playing Ethan. He's not our
              most well-mannered bot.</span
            >
          </div>
        </div>
        <p>
          Ethan Snide is a great example of a where personality clearly reflects
          in play style. You'll feel like you're playing against an 8 year old
          brat, both on the board and in the chat.
        </p>
      </div>
    </section>
    <section class="full-width-section">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        "
      >
        <h2>Join the <span class="accent">Future of Chess</span></h2>
        <p>
          Step into a new era of chess with <strong>PersonaPlay™</strong>.
          Whether you’re a beginner looking to learn or an advanced player
          seeking a challenge, our human-like bots provide the perfect opponents
          to help you achieve your goals.
        </p>

        <p>
          Discover the difference that human-like interaction can make. With
          <strong>PersonaPlay™</strong>, every game is more than just a
          match—it's an experience.
        </p>
        <button
          v-if="getUserState() == 'not-logged-in'"
          type="button"
          class="btn btn-info btn-lg"
          @click="
            () => {
              useModalsStore().showLogin(LoginModalState.Signup);
              track('personaplay', 'play_now_button_lower', 'click');
            }
          "
        >
          Play now for Free!
        </button>
        <a
          v-if="getUserState() == 'free'"
          type="button"
          class="btn btn-warning btn-lg"
          href="/premium"
          @click="
            () => {
              track('personaplay', 'go_premium_button_lower', 'click');
            }
          "
        >
          <i class="fa-solid fa-crown" /> Get premium
        </a>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { LoginModalState } from "@/types/internaltypes";
import { track } from "@/util/tracking";
import { useModalsStore } from "@/stores/modalsStore";
import ProfileCard from "@/components/common/ProfileCard.vue";
import { useBotsStore } from "@/stores/botStore";
import { isMobileWidth } from "@/util/util";
import { getPremiumState } from "@/util/premium";

function getUserState(): "not-logged-in" | "free" | "premium" {
  if (!useUserStore().user.loggedIn) {
    return "not-logged-in";
  }

  return getPremiumState() ? "premium" : "free";
}
</script>

<style scoped>
.pre-heading {
  color: var(--clr-main-light);
  margin-bottom: -4.25rem;
  font-family: "Cabin Sketch", "Neucha", sans-serif;
}

h2 {
  font-size: 2.5rem;
}

p {
  font-size: 1.1rem;
  color: var(--clr-main-light);
}

.accent {
  color: var(--clr-accent);
}

.full-width-section {
  width: 100vw;
  padding: 2rem;
  background-color: white;
}

section > div {
  max-width: 60rem;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
}

.feature-with-image {
  display: flex;
}

.feature-with-image > div {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  justify-content: center;
  text-align: left;
  margin: 1rem;
}

.feature-with-image > img {
  width: 45%;
}

.image-right > img {
  order: 1;
}

.final-feature-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.final-feature-with-image > img {
  width: 55%;
}

.stats-boxes {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .feature-with-image {
    flex-direction: column;
    align-items: center;
  }

  .image-right > img {
    order: 0;
  }

  .feature-with-image > div {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .feature-with-image > img {
    width: 100%;
  }

  .final-feature-with-image > img {
    width: 100%;
  }
}
</style>
