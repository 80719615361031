<template>
  <div
    v-show="show"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent); margin-bottom: 1.5rem"
  >
    <div class="main">
      <div class="premium-text-and-button">
        <div>
          <p>
            👋 Hello Beta user! As you might know, a month ago Chessiverse took
            the step out of Beta and, thanks to your help and feedback, is now
            fully live. As a sign of our gratitude, all beta users received a
            free month of Premium with no action needed. We hope you've enjoyed
            it!
          </p>
          <p>
            We of course hope you want to continue using Chessiverse Premium in
            the future, so here's a 50% discount. Just use the code
            <span>BETA50</span> at checkout! 🎉
          </p>
        </div>
        <a
          class="btn btn-warning"
          href="/premium"
          style="width: 12rem; max-height: 4rem"
          @click="
            () => {
              track('dashboard_notification', 'get_beta_premium', 'click');
            }
          "
        >
          <i class="fa-solid fa-crown" /> Get premium
        </a>
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_going_premmium_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { ref } from "vue";
import { track } from "@/util/tracking";
import { parseDate } from "@/util/util";

const userStore = useUserStore();

let show = ref(shouldShow());

function shouldShow() {
  if (localStorage.getItem("dismissedGoingPremiumBanner") == "true") {
    return false;
  }

  // Show to beta users who aren't premium yet
  if (
    !useUserStore().user.data?.roles?.includes("beta") ||
    useUserStore().user.data?.roles?.includes("premium") ||
    useUserStore().user.data?.roles?.includes("lifetime") ||
    useUserStore().user.data?.roles?.includes("yearly") ||
    useUserStore().user.data?.roles?.includes("monthly")
  ) {
    return false;
  }

  return true;
}

function dismissBanner() {
  show.value = false;
  localStorage.setItem("dismissedGoingPremiumBanner", "true");
}
</script>

<style scoped>
.premium-text-and-button {
  display: flex;
  gap: 1rem;
}

.premium-text-and-button strong {
  font-size: 1.1rem !important;
}

.premium-text-and-button a {
  width: 5rem;
}

@media (max-width: 768px) {
  .premium-text-and-button {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .premium-text-and-buttonstrong {
    font-size: 1.5rem;
  }

  .premium-text-and-button {
    text-align: center;
    text-wrap: balance;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.information-container {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.close-x {
  color: var(--clr-accent);
}

.close-x:hover {
  color: var(--clr-accent2);
}
</style>
