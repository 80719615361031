<template>
  <div class="bot-and-name">
    <div style="position: relative">
      <span
        v-if="locked"
        :style="{
          fontSize: imageSize / 3 + 'rem',
          textAlign: 'center',
          color: 'white',
          top: imageSize / 3 + 'rem',
          left: '0',
          right: '0',
          position: 'absolute',
          zIndex: 1,
          opacity: 0.8,
        }"
      >
        <i class="fa-solid fa-lock"></i>
      </span>
      <img
        alt="Bot profile image"
        class="profile-image-circle"
        :style="{
          background:
            'url(' + ps.img(bot.id, ImageType.BotBackground, '20') + ')',
          backgroundSize: 'cover',
          border: '2px solid #ddd',
          '--size': imageSize + 'rem',
          filter: locked ? 'saturate(30%) opacity(50%)' : '',
        }"
        :src="ps.img(bot.id, ImageType.BotProfile, '175')"
      />
      <div style="z-index: 100">
        <img
          alt="Golden star"
          v-if="!locked && bot.user?.hasWon"
          :style="{
            height: imageSize * 0.3 + 'rem',
            top: '0rem',
            right: '0rem',
            position: 'absolute',
            opacity: 1,
          }"
          src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp
              "
        />
        <img
          alt="Golden star outline"
          v-else-if="!locked"
          :style="{
            height: imageSize * 0.3 + 'rem',
            top: '0rem',
            right: '0rem',
            position: 'absolute',
            opacity: 0.9,
          }"
          src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp
              "
        />
      </div>
    </div>
    <div
      v-if="showPlaque"
      class="card profile-name-text"
      :style="{
        backgroundColor: bot.config.boardbg,
        zIndex: 10,
        whiteSpace: 'nowrap',
        fontSize: fontSize,
      }"
    >
      {{ bot.short_name }} ({{ bot.strength.estimated_elo }})
    </div>
  </div>
</template>
<script setup lang="ts">
import { ImageType } from "@/types/internaltypes";
import type { PropType } from "vue";
import type { Bot } from "@/types/apitypes";
import { usePageStore } from "@/stores/pageStore";

const props = defineProps({
  bot: { type: Object as PropType<Bot>, required: true },
  imageSize: { type: Number, default: 6 },
  fontSize: { type: String, default: "1rem" },
  showPlaque: { type: Boolean, default: true },
  locked: { type: Boolean, default: false },
});

const ps = usePageStore();
</script>
<style scoped>
.profile-image-circle {
  width: var(--size);
  height: var(--size);
  z-index: 10;
  border-radius: 50%;
  border: 1.5px solid #444;
}

.profile-name-text {
  position: absolute;
  bottom: -1rem;
  padding: 0 0.5rem;
}

.bot-and-name {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 100;
}
</style>
