<template>
  <div
    style="
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
    "
  >
    <div class="title bounce-top">
      <div class="btn-group" role="group">
        <input
          type="radio"
          class="btn-check white inactive"
          name="color-settings-white"
          id="color-settings-white"
          autocomplete="off"
          value="white"
          v-model="selected"
        />
        <label
          class="btn white inactive btn-outline-info"
          for="color-settings-white"
          >White</label
        >
        <input
          type="radio"
          class="btn-check black inactive"
          name="color-settings-black"
          id="color-settings-black"
          autocomplete="off"
          value="black"
          v-model="selected"
        />
        <label
          class="btn black inactive btn-outline-info"
          for="color-settings-black"
          >Black</label
        >
        <input
          type="radio"
          class="btn-check inactive"
          name="color-settings-random"
          id="color-settings-random"
          autocomplete="off"
          value="random"
          v-model="selected"
        />
        <label class="btn inactive btn-outline-info" for="color-settings-random"
          >Random</label
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { nextTick, onUnmounted, type PropType, ref, watch } from "vue";

const props = defineProps({
  initial: {
    type: String as PropType<"random" | "white" | "black" | null>,
    default: "random",
  },
});

let selected = ref(props.initial ? props.initial : "random");

const emit = defineEmits(["update"]);

onUnmounted(() => {});

watch(
  () => selected.value,
  () => {
    emit("update", selected.value);
  },
  { immediate: true }
);
</script>

<style scoped>
.inactive:not(:checked) + .inactive {
  background-color: var(--clr-main-lightest);
}

.white:checked + .white {
  background-color: white;
  color: rgb(23, 162, 184);
}

.black:checked + .black {
  background-color: black;
}

.title {
  display: flex;
  gap: 0.2rem;
  justify-content: center;
}

.color-container {
  display: flex;
  gap: 0.5rem;
  justify-content: start;
}

.time-container input {
  width: 4ch;
}
</style>
