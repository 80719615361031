const playstyles = ["Guardian", "Observer", "Mediator", "Hunter", "Savage"];
const playstyleTooltips = [
  "Guardians are the most defensive bots. They focus on protecting their pieces and safeguard their king.",
  "Observers has the most balanced playstyle. Waiting for the right opportunity without leaving themselves open for attack.",
  "Mediators are the most flexible playstyle. Adapting to their opponent's moves and finding the best counterplay.",
  "Hunters are the most tactical playstyle. Creating complications and finding the best moves in complex positions.",
  "Savages are the most aggressive playstyle. They attack their opponent's pieces and creating threats.",
];

function getImg(playstyle: string) {
  return `https://storage.googleapis.com/chessiverse1/images/elements/playstyle/${playstyle.toLowerCase()}.webp`;
}

export function personaCategories() {
  return playstyles.map((playstyle, index) => {
    return {
      id: index + 1,
      name: playstyle,
      tooltip: playstyleTooltips[index],
      img: getImg(playstyle),
    };
  });
}
