<template>
  <div
    class="card position"
    :class="'ph-no-capture' + (pi == highlightIndex ? ' highlight' : '')"
    v-for="(p, pi) in puzzles"
    :key="'puzzle' + pi"
    v-tippy="{
      content: iconsAndTooltips[pi].tooltip,
      placement: 'bottom',
    }"
    role="button"
    :ref="setRef(pi)"
    @click="
      () => {
        emit('userInput', { type: UserInput.ViewPuzzle, puzzle: p });
        track('puzzle_box', 'view_puzzle', 'click', {
          puzzle_id: p.id,
        });
      }
    "
  >
    <TheChessboard
      :board-config="getBoardConfig(p.fen, p.side)"
      :style="getSize()"
    />
    <i
      v-if="!(pi == highlightIndex && p.user_result == null)"
      :class="
        iconsAndTooltips[pi].icon +
        (iconsAndTooltips[pi].isUpdated ? ' rotate-in' : '')
      "
      :style="{
        top: getMargin(),
        left: getMargin(),
        fontSize: getFontSize(),
      }"
    />
  </div>
</template>
<script setup lang="ts">
import type { BoardConfig } from "vue3-chessboard";
import type { Puzzle, PuzzleSolveHistory } from "@/types/apitypes";
import { type PropType, reactive, watch } from "vue";
import { TheChessboard } from "vue3-chessboard";
import "vue3-chessboard/style.css";
import { UserInput } from "@/types/internaltypes";
import { track } from "@/util/tracking";

const props = defineProps({
  puzzles: {
    type: Object as PropType<Puzzle[]>,
    required: true,
  },
  highlightIndex: {
    type: Number,
    default: -1,
  },
  size: {
    type: String as PropType<"medium" | "small">,
    default: "medium",
  },
});

let iconsAndTooltips = reactive<
  { icon: string; tooltip: string; isUpdated: boolean | undefined }[]
>([]);
const itemRefs: (HTMLElement | null)[] = [];

function setRef(index: number): any {
  return (el: HTMLElement | null) => {
    itemRefs[index] = el;
  };
}

watch(
  () => props.puzzles,
  (puzzles) => {
    for (let i = 0; i < puzzles.length; i++) {
      let newIconAndClass = getPuzzleIconsAndTitle(puzzles[i].user_result);

      if (iconsAndTooltips[i] != null) {
        if (iconsAndTooltips[i].icon !== newIconAndClass.icon) {
          newIconAndClass.isUpdated = true;
        }
      }

      iconsAndTooltips[i] = newIconAndClass;
    }

    for (let i = 0; i < itemRefs.length; i++) {
      if (itemRefs[i] != null) {
        itemRefs[i]!.setAttribute("title", iconsAndTooltips[i].tooltip);
      }
    }
  },
  { immediate: true, deep: true }
);

const emit = defineEmits(["userInput"]);

function getFontSize() {
  if (props.size === "medium") {
    return "2.5rem";
  } else {
    return "2rem";
  }
}
function getMargin() {
  if (props.size === "medium") {
    return "0.75rem";
  } else {
    return "0.5rem";
  }
}
function getSize() {
  let size = props.size === "medium" ? 4 : 3;

  return {
    width: size + "rem",
    height: size + "rem",
  };
}

function getBoardConfig(fen: string, side: "white" | "black"): BoardConfig {
  return {
    fen: fen,
    orientation: side,
    viewOnly: true,
  };
}

function getPuzzleIconsAndTitle(
  puzzleResult: PuzzleSolveHistory | null | undefined
) {
  if (puzzleResult == null) {
    // Not attempted yet
    return {
      icon: "check-not-achieved check-icon fa-solid fa-circle-question",
      tooltip: "Not attempted yet",
      isUpdated: false,
    };
  } else if (puzzleResult.some((subArray) => subArray.includes("failed"))) {
    // Failed
    return {
      icon: "check-failed check-icon fa-solid fa-circle-xmark",
      tooltip: "Failed",
      isUpdated: false,
    };
  } else if (
    puzzleResult.every((subArray) =>
      subArray.every((element) => element === "correct_move")
    )
  ) {
    // Solved without hints
    return {
      icon: "check-achieved check-icon fa-solid fa-circle-check",
      tooltip: "Solved without hints",
      isUpdated: false,
    };
  } else {
    // Solved with hints
    return {
      icon: "check-achieved-with-hints check-icon fa-solid fa-circle-check",
      tooltip: "Solved with hints",
      isUpdated: false,
    };
  }
}
</script>
<style scoped>
.position {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

:deep(cg-board) {
  background-color: var(--clr-accent); /* Override the board background color */
}

.check-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;

  border-radius: 50%;
  --bs-btn-bg: var(--clr-rect-2);
  --bs-btn-border-color: var(--clr-rect-2);
  --bs-btn-hover-bg: var(--clr-rect-1);
  --bs-btn-hover-border-color: var(--clr-rect-1);
  --bs-btn-text: var(--clr-rect-1);
}

.check-achieved {
  background: white;
  color: var(--clr-accent4);
}

.check-achieved-with-hints {
  background: white;
  color: var(--clr-rect-2);
}

.check-failed {
  background: white;
  color: var(--clr-rect-5);
}

.highlight {
  border: 2px solid var(--clr-rect-5);
  filter: drop-shadow(0 0 0.1rem var(--clr-main))
    drop-shadow(0 0 0.1rem var(--clr-main));
}

.check-not-achieved {
  border-radius: 50%;
  color: #fff;
  background: #bbb;
  opacity: 0.8;
}

.rotate-in {
  animation: rotate-in 0.7s ease-out;
}

@keyframes rotate-in {
  0% {
    transform: scale(500%) rotate(0);
    opacity: 0;
  }
  50% {
    transform: scale(400%) rotate(50deg);
    opacity: 0;
  }
  100% {
    transform: scale(100%) rotate(0);
    opacity: 1;
  }
}
</style>
