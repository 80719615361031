<template>
  <div class="practicegrid">
    <div class="left">
      <MainPuzzleBox style="width: 100%" :puzzles="allPuzzleData" />
    </div>
    <div class="right">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            @click="
              () => {
                setSelectedSection('beginner');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'beginner',
                });
              }
            "
            >Beginner (0-500)</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="true"
            role="tab"
            @click="
              () => {
                setSelectedSection('novice');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'novice',
                });
              }
            "
            >Novice (500-1000)</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('intermediate');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'intermediate',
                });
              }
            "
            >Intermediate (1000-1500)</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('skilled');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'skilled',
                });
              }
            "
            >Skilled (1500-2000)</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('advanced');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'advanced',
                });
              }
            "
            >Advanced (2000+)</a
          >
        </li>
      </ul>
      <div v-if="loading" class="text-center">
        <Loader />
      </div>
      <PuzzleList v-else :puzzles="puzzleData" />
    </div>
  </div>
</template>

<script setup lang="ts">
import MainPuzzleBox from "@/components/puzzles/MainPuzzleBox.vue";
import PuzzleList from "@/components/puzzles/PuzzleList.vue";
import { nextTick, onMounted, ref } from "vue";
import { useModalsStore } from "@/stores/modalsStore";
import { useBotsStore } from "@/stores/botStore";
import { usePageStore } from "@/stores/pageStore";
import { useGeneralStore } from "@/stores/generalStore";
import { useRouter } from "vue-router";
import Loader from "@/components/util/Loader.vue";
import type { Puzzle } from "@/types/apitypes";
import { track } from "@/util/tracking";

const bs = useBotsStore();
const ps = usePageStore();
const gs = useGeneralStore();
const ms = useModalsStore();
const router = useRouter();

let loading = ref(true);
let allPuzzleData = ref<Puzzle[]>([]);
let puzzleData = ref<Puzzle[]>([]);
let selectedSection = ref<
  "beginner" | "novice" | "intermediate" | "skilled" | "advanced"
>("beginner");

// @ts-ignore - For some very strange reason, the bots are not loaded here on iOS. So have to make sure they're loaded.
if (Object.values(bs.bots).length === 0) {
  bs.refresh();
}

gs.getPuzzles().then((p) => {
  allPuzzleData.value = p;
  loading.value = false;
  setSelectedSection("beginner");
});

let sections = {
  beginner: { from: 0, to: 500 },
  novice: { from: 500, to: 1000 },
  intermediate: { from: 1000, to: 1500 },
  skilled: { from: 1500, to: 2000 },
  advanced: { from: 2000, to: 3000 },
};

onMounted(() => {
  if (localStorage.getItem("puzzleInfoShown") != "true") {
    ms.showPuzzleInfo(() => {
      localStorage.setItem("puzzleInfoShown", "true");
    });
  }
});

function debugBotNames() {
  //@ts-ignore
  return Object.values(bs.bots)
    .map((b: any) => b.name)
    .join(", ");
}

function debugBotRatings() {
  //@ts-ignore
  return Object.values(bs.bots)
    .map((b: any) => b.strength.estimated_elo)
    .join(", ");
}

function setSelectedSection(
  section: "beginner" | "novice" | "intermediate" | "skilled" | "advanced"
) {
  selectedSection.value = section;
  let range = sections[section];

  let tempData = allPuzzleData.value.filter(
    (p) =>
      bs.getBot(p.bot_id).strength.estimated_elo >= range.from &&
      bs.getBot(p.bot_id).strength.estimated_elo < range.to
  );

  nextTick().then(() => {
    puzzleData.value = tempData;
    document.querySelectorAll(".practicecategory").forEach((el) => {
      el.classList.remove("cardEntrance");
      el.classList.add("cardEntrance");
    });

    document.querySelectorAll(".practicesection > h2").forEach((el) => {
      el.classList.remove("cardEntrance");
      el.classList.add("cardEntrance");
    });
  });
}
</script>

<style scoped>
.active {
  background: linear-gradient(
    315deg,
    var(--clr-background-detail) 0%,
    var(--clr-rect-2) 100%
  );
}

.practicegrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 5rem;
}

.positionright img {
  width: 1.5rem;
  aspect-ratio: 1;
  display: inline-block;
}

@media (max-width: 992px) {
  .practicegrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

:deep(cg-board) {
  background-color: var(--clr-accent); /* Override the board background color */
}

@keyframes cardEntrance {
  from {
    opacity: 0;
    transform: scale(0.3);
    filter: hue-rotate(180deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
    filter: hue-rotate(0deg);
  }
}
.points > * {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
}

.points img {
  width: 2rem;
  aspect-ratio: 1;
}

.best-win img {
  width: 1.5rem;
  aspect-ratio: 1;
  margin: -0.3rem 0 0 0.2rem;
}

.categoryheader-points img {
  width: 2.3rem;
  aspect-ratio: 1;
}

.categoryheader-points div:first-child {
  font-size: 1.8rem;
}
.right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.right a {
  font-family: "Cabin Sketch", "Neucha", sans-serif;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 300px;
}
</style>
