<template>
  <div class="card-hover" style="position: relative">
    <span
      v-if="locked()"
      :style="{
        fontSize: getSize().height / 6 + getSize().unit,
        color: 'white',
        top: '0.5rem',
        left: '0.5rem',
        position: 'absolute',
        zIndex: 1,
      }"
    >
      <i class="fa-solid fa-lock"></i>
    </span>
    <div
      :id="randomId"
      class="flex-column"
      :style="{
        width: getSize().width + getSize().unit,
        height: getSize().height + getSize().unit,
        filter: locked() ? 'saturate(30%) opacity(50%)' : '',
      }"
      ref="observedDiv"
    >
      <a
        @click="
          () => {
            showProfileModal() ? ms.showBotProfile(internalBot.botId) : null;
            track('common', 'profile_card_image', 'click');
          }
        "
        :class="
          'card background-card ph-no-capture' +
          (fadeIntoViewport && isDivVisible ? ' fade-in' : '')
        "
        @mouseover="resetWonAgainstBot(internalBot.botId)"
        :style="{
          cursor: anonymous ? 'auto' : 'pointer',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: getSize().width + getSize().unit,
          width: getSize().width + getSize().unit,
          background:
            'url(' +
            ps.img(internalBot.botId, ImageType.BotBackground, '20') +
            ')',
        }"
      >
        <img
          class="bot-profile-image"
          :src="ps.img(internalBot.botId, ImageType.BotProfile, '175')"
          :style="{
            display: !fadeIntoViewport || isDivVisible ? 'flex' : 'none',
            filter: anonymous ? 'brightness(0)' : 'brightness(1)',
          }"
          alt="Bot profile image"
        />
        <div
          :style="{
            top: '0rem',
            right: '0rem',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }"
        >
          <img
            v-if="showPlaystyle"
            :src="playstyles[internalBot!.playstyleCategory! - 1].img"
            alt="Playstyle"
            :style="{
              height: getSize().height / 6 + getSize().unit,
            }"
          />
        </div>
        <div
          :style="{
            bottom: '-0.2rem',
            right: '0.2rem',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }"
        >
          <span
            :style="{
              color: 'white',
              fontSize: getSize().fontSize + getSize().unit,
              textAlign: 'right',
            }"
            ><i class="fa-solid fa-arrow-trend-up" /> {{ internalBot.rating }}
          </span>
        </div>
        <div v-if="!anonymous && bot != null && showStar">
          <img
            alt="Golden star"
            v-if="!locked() && bot.user?.hasWon"
            :style="{
              height: getSize().height / 6 + getSize().unit,
              top: '0rem',
              left: '0rem',
              position: 'absolute',
              opacity: 1,
            }"
            :class="{ pulsatefwd: bot.id === wonAgainstBot }"
            src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp
              "
          />
          <img
            alt="Golden star outline"
            v-else-if="!locked()"
            :style="{
              height: getSize().height / 6 + getSize().unit,
              top: '0rem',
              left: '0rem',
              position: 'absolute',
              opacity: 0.9,
            }"
            src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp
              "
          />
        </div>
      </a>
      <a
        v-if="showName"
        class="card ph-no-capture"
        style="
          margin-top: 0.2rem;
          background-color: white;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
        "
        @click="
          () => {
            showProfileModal() ? ms.showBotProfile(internalBot.botId) : null;
            track('common', 'profile_card', 'click');
          }
        "
      >
        <span
          :style="
            getBotNameStyle(
              !anonymous &&
                internalBot.liveDate != null &&
                new Date() < internalBot.liveDate
            )
          "
          >{{ anonymous ? "?" : internalBot.name }}
        </span>
        <span
          v-if="displayOccupation && internalBot.occupation != null"
          style="color: #b7b7b7"
          >{{ internalBot.occupation }}</span
        >
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalsStore } from "@/stores/modalsStore";
import { usePageStore } from "@/stores/pageStore";
import type { Bot } from "@/types/apitypes";
import { ImageType, type BotMock } from "@/types/internaltypes";
import { ref, type PropType, type Ref, onMounted, onUnmounted } from "vue";
import { getFlagUrl, isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";
import { useUserStore } from "@/stores/userStore";
import { useBotsStore } from "../../stores/botStore";
import { personaCategories } from "@/util/personplaycategory";

const ms = useModalsStore();
const us = useUserStore();

const ps = usePageStore();
const props = defineProps({
  anonymous: { type: Boolean, default: false },
  unknown: { type: Boolean, default: false },
  bot: { type: Object as PropType<Bot>, default: null },
  botMock: { type: Object as PropType<BotMock>, default: null },
  profileScale: { type: String, default: "default" },
  profileScaleSet: {
    type: Object as PropType<{
      width: number;
      height: number;
      fontSize?: number;
      unit?: string;
    }>,
  },
  displayOccupation: { type: Boolean, default: false },
  showName: { type: Boolean, default: true },
  showStar: { type: Boolean, default: true },
  showPlaystyle: { type: Boolean, default: false },
  alwaysUnlocked: { type: Boolean, default: false },
  fadeIntoViewport: { type: Boolean, default: false },
});

const playstyles = personaCategories();

const isDivVisible = ref(true);
const observedDiv = ref<HTMLElement | null>(null);

const observerCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  entries.forEach((entry) => {
    isDivVisible.value = entry.isIntersecting;
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
    }
  });
};

onMounted(() => {
  if (props.fadeIntoViewport && observedDiv.value) {
    const observer = new IntersectionObserver(observerCallback);
    observer.observe(observedDiv.value);
  }
});

onUnmounted(() => {
  if (props.fadeIntoViewport && observedDiv.value) {
    const observer = new IntersectionObserver(observerCallback);
    observer.unobserve(observedDiv.value);
  }
});

let randomId = Math.random().toString(36).substring(7);

function showProfileModal() {
  return props.bot != null && !props.anonymous;
}

let internalBot: BotMock;
if (props.unknown) {
  internalBot = {
    name: "?",
    botId: "unknown",
    countryCode: "unknown",
    countryName: "unknown",
    color: "white",
    //@ts-ignore Letting this slide here, better might be to use -1 or so and replace it above with a question mark instead
    rating: "?",
    playstyleCategory: 0,
  };
} else if (props.bot != null) {
  internalBot = {
    name: props.bot.name,
    botId: props.bot.id,
    countryCode: props.bot.country.code,
    countryName: props.bot.country.name,
    color: props.bot.config.boardbg,
    rating: props.bot.strength.estimated_elo,
    liveDate: new Date(props.bot.live_date),
    occupation: props.bot.occupation,
    playstyleCategory: props.bot.strength.playstyle_category,
  };
} else if (props.botMock != null) {
  internalBot = props.botMock;
  internalBot.color = internalBot.color ?? "white";
} else {
  throw new Error("Either bot or botMock must be provided");
}

function getSize() {
  if (props.profileScaleSet) {
    let parsedSize = JSON.parse(JSON.stringify(props.profileScaleSet));
    if (props.profileScaleSet.unit === undefined) {
      parsedSize.unit = "rem";
    }

    return parsedSize;
  }

  switch (props.profileScale) {
    case "small":
      return { width: "8", height: "9.6", fontSize: "0.8", unit: "rem" };
    case "card":
      return { width: "5", height: "6", fontSize: "1", unit: "rem" };
    case "default":
    default:
      return { width: "10", height: "12", fontSize: "1", unit: "rem" };
  }
}

const wonAgainstBot: Ref<string | null> = ref(
  ps.wonAgainstBotIdForTheFirstTime
);

const resetWonAgainstBot = (hoveredBotId: string) => {
  if (hoveredBotId == wonAgainstBot.value) {
    wonAgainstBot.value = null;
    ps.handledWonAgainstBotIdForTheFirstTime();
  }
};

function locked() {
  return !props.alwaysUnlocked && useBotsStore().isLocked(props.bot);
}

function getBotNameStyle(notLiveYet: boolean): any {
  let style: any = {
    whiteSpace: "nowrap",
    background:
      'linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0.7) 100%), url("' +
      getFlagUrl(internalBot.countryCode) +
      '")',
    backgroundSize:
      getSize().fontSize * 2 +
      getSize().unit +
      " " +
      getSize().fontSize * 1.5 +
      getSize().unit,
    height: getSize().fontSize * 1.5 + getSize().unit,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
  };

  if (notLiveYet) {
    style["color"] = "red";
  }

  style["fontSize"] = getSize().fontSize + getSize().unit;

  return style;
}
</script>

<style scoped>
.small {
  position: relative;
}
.profile-image-circle {
  position: relative;
  height: 4rem;
  border-radius: 50%;
  z-index: 10;
  border: 1.5px solid #444;
}

.profile-container {
  padding-top: 1rem; /* This counteracts the margin-bottom -3rem in .profile-name text, and keeps the containing div roughtly 4rem (the height of the image) */
}
.profile-name-text {
  margin: 0 0 -3rem 2rem;
  width: 14rem;
  text-align: center;
}

.profile-name-text .card-body {
  padding: 0.1rem; /* Overrides the card boy padding */
}

.card-hover {
  transition: all 0.2s ease;
}
.card-hover:hover {
  position: relative;
  z-index: 1000;
  transform: scale(1.1) perspective(200px) rotateX(10deg) translateY(-0.5rem);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
}
.card-hover:hover > div {
  filter: none !important;
}

.background-card {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
